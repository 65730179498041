import React from "react";

import Home from "./../components/Home";
import Platform from "../components/Platform";
import Certificate from "../components/Certificate";
import Contact from "../components/Contact";
import Italian from "../components/Italian";
import Register from "../components/Register";
import Service from "../components/Service";
import Shop from "../components/Shop";
import Translation from "../components/Translation";
import Withdraw from "../components/Withdraw";
import Start from "../components/Start";
import Calender from "../components/Calendar";
import Regulations from "../components/Regulations";
import Reserve from "../components/Reserve";
import Result from "../components/Result";
import Test from "../components/Test";
import Units from "../components/Units";
import Upgrade from "../components/Upgrade";
import Corporate from "./../components/Corporate";
import Welcome from "./../components/Welcome";
import ContactUs from "../components/ContactUs";
import Aboutus from "./../components/Aboutus";
import Login from "./../components/Login";
import TermandCondition from "./../components/TermandCondition";
import Profile from "./../components/Profile";
import Material from "../components/Material";
import Payment from "./../components/Payment";
import Success from "./../components/Success";
import Canceled from "./../components/Canceled";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Dashboard from "./../components/Dashboard";
import ExamAppointmentBooking from "../components/ExamAppointmentBooking";
import MiddleTest from './../components/MiddleTest';
import MiddleTestResult from './../components/MiddleTestResult';
import ForgotPassword from './../components/ForgotPassword';
import UpgradedCourse from './../components/UpgradedCourse';

export const headerRouterConfig = [
  {
    path: "/",
    label: "Home",
    component: <Home />,
  },
  {
    path: "/platform",
    label: "ThePlatform",
    component: <Platform />,
  },
  {
    path: "/shop",
    label: "Shop",
    component: <Shop />,
  },
  {
    path: "/service",
    label: "Services",
    children: [
      {
        path: "certificate",
        label: "Certificates",
        component: <Certificate />,
      },
      {
        path: "corporate",
        label: "CorporateCourses",
        component: <Corporate />,
      },
      {
        path: "",
        label: "BusinessServices",
        component: <Service />,
      },
      {
        path: "translation",
        label: "Translation",
        component: <Translation />,
      },
      {
        path: "italian",
        label: "Italianforforeigners",
        component: <Italian />,
      },
    ],
  },
  {
    path: "/about-us",
    label: "AboutUs",
    component: <Aboutus />,
  },
  {
    path: "/contact",
    label: "Contact",
    component: <Contact />,
  },
];

export const authRouter = [
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/login/:id",
    component: <Login />,
  },
];

export const privateAuthRoute = [
  {
    path: "/material/:id",
    label: "Material",
    component: <Material />,
  },
  {
    path: "/examappointmentbooking",
    label: "examappointmentbooking",
    component: <ExamAppointmentBooking />,
  },
];

export const privateRoute = [
  // {
  //   path: "/welcome",
  //   label: "Welcome",
  //   component: <Welcome />,
  // },
  {
    path: "/welcome",
    label: "Welcome",
    component: <Dashboard />,
  },
  {
    path: "/units",
    label: "Units",
    component: <Units />,
  },
  {
    path: "/calendar",
    label: "Calendar",
    component: <Calender />,
  },
  {
    path: "/regulations",
    label: "Regulations",
    component: <Regulations />,
  },
  {
    path: "/contactus",
    label: "Contact Us",
    component: <ContactUs />,
  },
  {
    path: "/profile",
    label: "Profile",
    component: <Profile />,
  },
];

export const otherRouter = [
  {
    path: "/middle-test",
    component: <MiddleTest />,
  },
  {
    path: "/startNow",
    component: <Start />,
  },
  {
    path: "/withdraw",
    component: <Withdraw />,
  },
  {
    path: "/Reserve",
    component: <Reserve />,
  },
  {
    path: "/Result",
    component: <Result />,
  },
  {
    path: "/middle-test-result",
    component: <MiddleTestResult />,
  },
  {
    path: "/Test",
    component: <Test />,
  },
  {
    path: "/Upgrade",
    component: <Upgrade />,
  },
  {
    path: "/terms-and-condition",
    component: <TermandCondition />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/payment",
    component: <Payment />,
  },
  {
    path: "/success",
    component: <Success />,
  },
  {
    path: "/upgraded-course",
    component: <UpgradedCourse />,
  },
  {
    path: "/canceled",
    component: <Canceled />,
  },
];

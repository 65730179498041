import React, { useEffect} from "react";
import Body from './common/Body'
import imgfounder2 from "../assets/images/img-founder-2.png"
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpgradedCourse = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        logout();
    }, []);

    const logout = () => {
        toast.success("Logout Successfully", { autoClose: 3000 });
        sessionStorage.removeItem("auth_token");
        sessionStorage.clear();
        console.log('logout');
        navigate("/login");
    };

    return (
        <Body>
            <section className="belford-founder-hero-section" id="belford-founder-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col-lg-12 align-items-cente ">
                        <div className="belford-register-box text-center">
                                <h1> <span>Success</span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-founder-about-section" id="belford-founder-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9  text-center">
                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#1ba62f" className="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                                </svg>
                            </div>
                            {/* <h4>Thank you! You have successfully enrolled in Belford Woods School.</h4> */}
                            <h4>{t("thank_you.text")}</h4>
                            <div className="form text-center mt-3">
                                <Link to="/login" type="submit" className="belford-btn">Start your English course now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default UpgradedCourse